var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.selectStatus && _vm.loggedIn.user.role != 'USER')?_c('div',{staticClass:"row"},[_c('b-button',{staticClass:"mb-4 ml-3",attrs:{"variant":"light"},on:{"click":_vm.passAlerts}},[_vm._v(" "+_vm._s(_vm.$t("buttons.aquit.text"))+" ")])],1):_vm._e(),_c('div',{staticClass:"table-responsive mb-3 bg-white rounded-lg",staticStyle:{"min-height":"430px"}},[_c('v-data-table',{staticClass:"elevation-1",style:({ cursor: `pointer` }),attrs:{"headers":_vm.fieldsComputed2,"items":_vm.alertsList,"items-per-page":500,"single-select":false,"loading":_vm.busy,"loading-text":this.$t('dataTable.loading.text'),"no-data-text":this.$t('dataTable.noAlerts.text'),"item-key":"id","show-select":"","hide-default-footer":""},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:`item.state`,fn:function({ item }){return [_c('div',{staticClass:"badge font-size-12 text-center",class:{
            'badge-soft-warning': `${item.state}` === 'NEW',
            'badge-soft-info': `${item.state}` === 'OPENED',
            'badge-soft-secondary': `${item.state}` === 'SUSPENDED',
            'badge-soft-success': `${item.state}` === 'CLOSED',
          }},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:`item.type`,fn:function({ item }){return [_c('div',{staticClass:"badge font-size-12 text-center",class:{
            'badge-soft-primary': `${item.type}` === 'DOWN',
            'badge-soft-danger': `${item.type}` === 'UP',
          }},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:`item.value`,fn:function({ item }){return [_c('div',{staticClass:"font-size-14 text-center font-weight-bold",class:{
            'text-danger': `${item.value}` > 8,
            'text-primary': `${item.value}` < 2,
            'text-success': `${item.value}` > 2 && `${item.value}` < 8,
          }},[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b-button-group',[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.passAlert(item)}}},[_vm._v(_vm._s(_vm.$t("buttons.aquit.text"))+" ")]),_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){$event.stopPropagation();return _vm.openDevice(item)}}},[_c('i',{staticClass:"ri-eye-line"})])],1)],1)])]}},{key:`item.date`,fn:function({ item }){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:`item.vaccinState`,fn:function({ item }){return [_c('div',{staticClass:"text-center",staticStyle:{"min-width":"80px"}},[_vm._v(" "+_vm._s(item.vaccinState)+" ")])]}}],null,true),model:{value:(_vm.selectedAlerts),callback:function ($$v) {_vm.selectedAlerts=$$v},expression:"selectedAlerts"}}),_c('AquitAlert',{attrs:{"alert":_vm.alert,"causes":_vm.causes,"operations":_vm.operations,"actions":_vm.actions,"states":_vm.states},on:{"aquitAlert":_vm.aquit}}),_c('Logs',{attrs:{"device":_vm.device}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }