<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import AlertsData from "./alerts-data";
import AquitAlerts from "../alerts/aquit-alerts";

import {
  layoutComputed,
  authComputed,
  offlineAlertsMethods,
  statesMethods,
  causesMethods,
  actionsMethods,
  operationsMethods,
  regionsMethods,
  provincesMethods,
  vaccintypesMethods,
  offlineAlertsComputed,
  statesComputed,
  causesComputed,
  actionsComputed,
  operationsComputed,
  regionsComputed,
  provincesComputed,
  vaccintypesComputed,
} from "@/state/helpers";
/**
 * Alerts component
 */
export default {
  page: {
    title: "Alerts",
    meta: [{ name: "Alerts" }],
  },
  components: {
    Layout,
    PageHeader,
    AlertsData,
    AquitAlerts,
  },
  mounted() {
    this.retrieveAlerts({ perPage: this.perPage }).then(async () => {
      await this.retrieveStates();
      await this.retrieveCauses();
      await this.retrieveActions();
      await this.retrieveOperations();
      await this.retrieveVaccintypes();
      await this.retrieveRegions();
      //await this.retrieveProvinces();
    });
  },
  data() {
    return {
      title: "titles.alerts.text",
      busy: true,
      currentPage: 1,
      perPage: 500,
      pageOptions: [10, 25, 50, 100, 500],

      loaderDashboard: false,

      alertsData: [],
      statesData: [],
      causesData: [],
      actionsData: [],
      operationsData: [],

      vaccintypesData: [],

      alertsAquit: [],

      typesFilterData: [
        { value: null, text: "--All--" },
        { value: "DOWN", text: "DOWN" },
        { value: "UP", text: "UP" },
      ],
      stockTypesFilterData: [
        { value: null, text: "--All--" },
        { value: "24 H", text: "24 H" },
        { value: "VIDE", text: "VIDE" },
      ],
      statesFilterData: [{ value: null, text: "--All--" }],
      regionsFilterData: [{ value: null, text: "--All--" }],
      provincesFilterData: [{ value: null, text: "--All--" }],
      vaccintypesFilterData: [{ value: null, text: "--All--" }],

      alertsFilter: {
        serial: null,
        name: null,
        //type: null,
        stockTypes: null,
        regions: null,
        provinces: null,
        states: null,
        vaccintypes: null,
      },
      stockTypes: [null],
      vaccintypes: [null],
      states: [null],
      regions: [null],
      provinces: [null],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...offlineAlertsComputed,
    ...statesComputed,
    ...causesComputed,
    ...actionsComputed,
    ...operationsComputed,
    ...regionsComputed,
    ...provincesComputed,
    ...vaccintypesComputed,

    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.alerts.text"),
          active: true,
        },
      ];
    },
  },
  methods: {
    ...offlineAlertsMethods,
    ...statesMethods,
    ...causesMethods,
    ...actionsMethods,
    ...operationsMethods,
    ...regionsMethods,
    ...provincesMethods,
    ...vaccintypesMethods,
    showModalAquit() {
      this.$bvModal.show("aquit-alerts-modal");
    },
    hideModalAquit() {
      this.$bvModal.hide("aquit-alerts-modal");
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    passAlerts({ alertsToAquit }) {
      this.alertsData = this.getAlerts.results;
      this.alertsAquit = alertsToAquit ? alertsToAquit : this.getAlerts.results;
      this.showModalAquit();
    },
    aquitAll(alerts) {
      this.busy = true;
      if (this.alertsAquit.length == this.getAlerts.results.length) {
        this.aquitAlerts(alerts).then((alertsResponse) => {
          if (alertsResponse.closed) {
            let total = this.getAlerts.totalResults;
            let current = this.currentPage;
            let perP = this.perPage;
            let totalCalc = Math.ceil(total / perP);
            if (totalCalc == current && current >= 1) {
              this.currentPage -= 1;
            }
            this.retrieveAlerts({ perPage: this.perPage });
          }
          this.busy = false;
          this.hideModalAquit();
        });
      } else {
        this.aquitPartOfAlerts(alerts).then(() => {
          this.busy = false;
        });
        this.hideModalAquit();
      }
    },
    retrieveAlerts({
      perPage,
      page,
      serial,
      name,
      stockTypes,
      regions,
      provinces,
      states,
      vaccintypes,
    }) {
      let params = {
        page: page ? page : this.currentPage,
        limit: perPage ? perPage : this.perPage,
        sortBy: "alertedAt:desc",
        type: "OFFLINE",
        serial: serial ? serial : this.alertsFilter.serial,
        name: name ? name : this.alertsFilter.name,
        stockTypes: stockTypes ? stockTypes : this.alertsFilter.stockTypes,
        regions: regions ? regions : this.alertsFilter.regions,
        provinces: provinces ? provinces : this.alertsFilter.provinces,
        states: states ? states : this.alertsFilter.states,
        vaccinTypes: vaccintypes ? vaccintypes : this.alertsFilter.vaccintypes,
      };
      let paramsFilter = this.cleanObject(params);
      this.busy = true;
      return this.getAlertsBy(paramsFilter)
        .then((alerts) => {
          this.busy = false;
          return alerts;
        })
        .catch((error) => {
          return error;
        });
    },
    retrieveStates() {
      if (this.getStates.results)
        return this.chargeStates(this.getStates.results);
      else
        return this.getStatesBy({ limit: 100 }).then((states) => {
          return states;
        });
    },
    retrieveVaccintypes() {
      if (this.getVaccintypes.results)
        return this.chargeVaccintypes(this.getVaccintypes.results);
      else
        return this.getVaccintypesBy({ limit: 100 }).then((vaccintypes) => {
          return vaccintypes;
        });
    },
    retrieveCauses() {
        return this.getCausesBy({ limit: 100, type:2 }).then((causes) => {
          return causes;
        });
    },
    retrieveActions() {
      if (this.getActions.results) this.actionsData = this.getActions.results;
      else
        return this.getActionsBy({ limit: 100 }).then((actions) => {
          return actions;
        });
    },
    retrieveOperations() {
      return this.getOperationsBy({ limit: 100, type:1 }).then((operations) => {
          return operations;
        });
    },
    retrieveRegions() {
      if (this.getRegions.results)
        return this.chargeRegions(this.getRegions.results);
      else
        return this.getRegionsBy({ limit: 100 }).then((regions) => {
          return regions;
        });
    },
    retrieveProvinces(regions) {
      if (this.getProvinces.results)
        return this.chargeProvinces(this.getProvinces.results, regions);
      else
        return this.getProvincesBy({ limit: 100 }).then((provinces) => {
          return provinces;
        });
    },

    chargeStates(states) {
      this.statesData = states;
      let sData = states;
      sData.map((obj) => {
        let notAllowedStates = ["CLOSED", "IGNORED"];
        if (!notAllowedStates.includes(obj.name))
          this.statesFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    chargeVaccintypes(vaccintypes) {
      this.vaccintypesData = vaccintypes;
      let vData = vaccintypes;
      vData.map((obj) => {
          this.vaccintypesFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    chargeRegions(regions) {
      let rData = regions;
      rData.map((obj) => {
        this.regionsFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    chargeProvinces(provinces, regions) {
      this.provincesFilterData = [{ value: null, text: "--All--" }];
      let pData = provinces;
      pData.map((obj) => {
        if(regions.includes(obj.region)) this.provincesFilterData.push({ value: obj.id, text: obj.name });
      });
    },

    handlePageChange(value) {
      this.currentPage = value;
      this.retrieveAlerts({ ...this.alertsFilter });
    },

    showModalSearch() {
      this.$bvModal.show("search-modal");
    },
    hideModalSearch() {
      this.$bvModal.hide("search-modal");
    },

    search() {
      this.currentPage = 1;
      this.retrieveAlerts({ ...this.alertsFilter, page: 1 }).then(() => {
        this.hideModalSearch();
      });
    },

    clear() {
      this.alertsFilter = {
        serial: null,
        name: null,
        //type: null,
        stockTypes: null,
        regions: null,
        provinces: null,
        states: null,
      };
      this.stockTypes = [null];
      this.states = [null];
      this.regions = [null];
      this.provinces = [null];
    },

    cancel() {
      return;
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },
    /* cleanObjects(objs) {
      for (let item in objs) {
        for (let propName in objs[item]) {
          if (
            objs[item][propName] === null ||
            objs[item][propName] === undefined ||
            objs[item][propName] === ""
          ) {
            delete objs[item][propName];
          }
        }
        return objs[item];
      }
    }, */
  },
  watch: {
    getAlerts(newVal) {
      this.alertsData = newVal.results;
    },
    getStates(newVal) {
      this.chargeStates(newVal.results);
      /* this.statesData = newVal.results;
      let sData = newVal.results;
      sData.map((obj) => {
        let notAllowedStates = ["CLOSED", "IGNORED"];
        if (!notAllowedStates.includes(obj.name))
          this.statesFilterData.push({ value: obj.id, text: obj.name });
      }); */
    },
    getVaccintypes(newVal) {
      this.chargeVaccintypes(newVal.results);
    },
    getCauses(newVal) {
      this.causesData = newVal.results;
    },
    getActions(newVal) {
      this.actionsData = newVal.results;
    },
    getOperations(newVal) {
      this.operationsData = newVal.results;
    },
    getRegions(newVal) {
      this.chargeRegions(newVal.results);
    },
    getProvinces(newVal) {
      this.chargeProvinces(newVal.results, this.regions);
    },
    perPage(newVal) {
      this.retrieveAlerts({ ...this.alertsFilter, perPage: newVal, page: 1 });
    },
    states(newVal) {
      let lenStates = newVal.length;

      if (!newVal[lenStates - 1] && lenStates > 1) {
        this.states = [null];
        this.alertsFilter.states = null;
      } else if (newVal[lenStates - 1] && newVal.includes(null)) {
        var filteredStates = this.states.filter(function (item) {
          return item != null;
        });
        this.states = filteredStates;
      } else if (newVal[lenStates - 1] && !newVal.includes(null)) {
        this.alertsFilter.states = newVal;
      } else if (newVal.length == 0) {
        this.states = [null];
        this.alertsFilter.states = null;
      }
    },
    stockTypes(newVal) {
      let lenTypes = newVal.length;

      if (!newVal[lenTypes - 1] && lenTypes > 1) {
        this.stockTypes = [null];
        this.alertsFilter.stockTypes = null;
      } else if (newVal[lenTypes - 1] && newVal.includes(null)) {
        var filteredStockTypes = this.stockTypes.filter(function (item) {
          return item != null;
        });
        this.stockTypes = filteredStockTypes;
      } else if (newVal[lenTypes - 1] && !newVal.includes(null)) {
        this.alertsFilter.stockTypes = newVal;
      } else if (newVal.length == 0) {
        this.stockTypes = [null];
        this.alertsFilter.stockTypes = null;
      }
    },
    vaccintypes(newVal) {
      let lenVaccintypes = newVal.length;

      if (!newVal[lenVaccintypes - 1] && lenVaccintypes > 1) {
        this.vaccintypes = [null];
        this.alertsFilter.vaccintypes = null;
      } else if (newVal[lenVaccintypes - 1] && newVal.includes(null)) {
        var filteredVaccintypes = this.vaccintypes.filter(function (item) {
          return item != null;
        });
        this.vaccintypes = filteredVaccintypes;
      } else if (newVal[lenVaccintypes - 1] && !newVal.includes(null)) {
        this.alertsFilter.vaccintypes = newVal;
      } else if (newVal.length == 0) {
        this.vaccintypes = [null];
        this.alertsFilter.vaccintypes = null;
      }
    },
    regions(newVal) {
      let lenRegions = newVal.length;

      if (!newVal[lenRegions - 1] && lenRegions > 1) {
        this.regions = [null];
        this.alertsFilter.regions = null;
      } else if (newVal[lenRegions - 1] && newVal.includes(null)) {
        var filteredRegions = this.regions.filter(function (item) {
          return item != null;
        });
        this.regions = filteredRegions;
      } else if (newVal[lenRegions - 1] && !newVal.includes(null)) {
        this.alertsFilter.regions = newVal;
      } else if (newVal.length == 0) {
        this.regions = [null];
        this.alertsFilter.regions = null;
      }
      this.retrieveProvinces(newVal);
    },
    provinces(newVal) {
      let lenProvinces = newVal.length;

      if (!newVal[lenProvinces - 1] && lenProvinces > 1) {
        this.provinces = [null];
        this.alertsFilter.provinces = null;
      } else if (newVal[lenProvinces - 1] && newVal.includes(null)) {
        var filteredProvinces = this.provinces.filter(function (item) {
          return item != null;
        });
        this.provinces = filteredProvinces;
      } else if (newVal[lenProvinces - 1] && !newVal.includes(null)) {
        this.alertsFilter.provinces = newVal;
      } else if (newVal.length == 0) {
        this.provinces = [null];
        this.alertsFilter.provinces = null;
      }
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div class="row">
      <div class="col-4 col-lg-2 d-inline-flex align-items-center">
        <div class="mr-2 font-weight-bold">
          {{ $t("labels.numberAlerts.text") }}
        </div>
        <b-form-select
          style="max-width: 70px"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
        ></b-form-select>
      </div>
      <!-- <div class="col-7 col-lg-9 text-right">
        <b-button variant="light" @click="passAlerts"> Aquit All </b-button>
      </div>
      <AquitAlerts
        v-bind:alerts="alertsData"
        :states="statesData"
        :causes="causesData"
        :actions="actionsData"
        @aquitAlert="aquitAll"
      /> -->
      <div class="col-1 col-lg-1 d-flex">
        <div
          v-if="busy"
          class="d-flex justify-content-center align-items-center"
          style="position: relative; z-index: 200"
        >
          <b-spinner label="Loading..." variant="primary"></b-spinner>
        </div>
      </div>
      <div class="col-7 col-lg-9 d-flex justify-content-end">
        <!-- <b-button class="mr-2" variant="light" @click="passAlerts">
          {{ $t("buttons.aquitAll.text") }}
        </b-button> -->
        <AquitAlerts
          v-bind:alerts="alertsAquit"
          :states="statesData"
          :causes="causesData"
          :actions="actionsData"
          :operations="operationsData"
          :vaccintypes="vaccintypesData"
          @aquitAlert="aquitAll"
        />
        <!-- ---------------------------------------------------- SEARCH -------------------------------------------------- -->
        <b-button variant="light" @click="showModalSearch">
          <i class="ri-filter-3-line"></i>
        </b-button>
        <b-modal
          id="search-modal"
          ref="search-modal"
          size="lg"
          :title="this.$t('modals.filterAlert.text')"
        >
          <!-- Serial -->
          <div class="row">
            <div class="col-6 col-lg-6">
              <label for="stateSh">Serial</label>
              <b-form-input
                id="stateSh"
                v-model="alertsFilter.serial"
                placeholder="Enter serial"
                class="mb-1"
              ></b-form-input>
            </div>
            <!-- Name -->
            <div class="col-6 col-lg-6">
              <label for="nameSh">{{ $t("modals.name.text") }}</label>
              <b-form-input
                id="nameSh"
                v-model="alertsFilter.name"
                :placeholder="$t('modals.name.text')"
                class="mb-1"
              ></b-form-input>
            </div>
          </div>
          <!-- Type -->
          <!-- <div class="row">
            <div class="col-12 col-lg-12" data-app>
              <label for="typeSh">{{ $t("modals.type.text") }}</label>
              <b-form-select
                id="typeSh"
                v-model="alertsFilter.type"
                :options="typesFilterData"
                class="mb-1"
              ></b-form-select>
            </div>
          </div> -->
          <!-- Status -->
          <div class="row">
            <div class="col-6 col-lg-6" data-app>
              <label for="stateSh">{{ $t("modals.status.text") }}</label>
              <v-select
                id="stateSh"
                v-model="states"
                :items="statesFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
            <div class="col-6 col-lg-6" data-app>
              <label for="stypeSh">{{ $t("modals.stockType.text") }}</label>
              <v-select
                id="stypeSh"
                v-model="stockTypes"
                :items="stockTypesFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
          </div>
          <!-- Region -->
          <div class="row">
            <div class="col-6 col-lg-6" data-app>
              <label for="regionSh">{{ $t("modals.region.text") }}</label>
              <v-select
                id="regionSh"
                v-model="regions"
                :items="regionsFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
            <!-- Province -->
            <div class="col-6 col-lg-6" data-app>
              <label for="provinceSh">{{ $t("modals.province.text") }}</label>
              <v-select
                id="provinceSh"
                v-model="provinces"
                :items="provincesFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
          </div>
          <!-- Vaccin Types -->
          <div class="row">
            <div class="col-12 col-lg-12" data-app>
              <label for="vaccinSh">{{ $t("modals.vaccinType.text") }}</label>
              <v-select
                id="vaccinSh"
                v-model="vaccintypes"
                :items="vaccintypesFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
          </div>

          <template #modal-footer="{ cancel }">
            <div class="row p-2">
              <div class="col-10 text-right p-1">
                <b-button variant="success" class="text-white" @click="search">
                  {{ $t("buttons.filter.text") }}
                </b-button>
              </div>
              <div class="col-2 text-center p-1">
                <b-button @click="cancel()">
                  {{ $t("buttons.cancel.text") }}
                </b-button>
              </div>
            </div>
          </template>
        </b-modal>
        <!-- ---------------------------------------------------- END SEARCH -------------------------------------------------- -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <AlertsData
          v-bind:alerts="alertsData"
          :busy="busy"
          :states="statesData"
          :causes="causesData"
          :actions="actionsData"
          :operations="operationsData"
          @alertsToAquit="passAlerts"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="getAlerts.totalResults"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>